import { Home } from "./components/home/Home"

const AppRoutes = [
  {
    index: true,
    element: <Home />
  }
]

export default AppRoutes
