import React from 'react'

const Footer = () => {
  return (
    <footer className='w-full py-[3rem] mt-[5rem] flex items-center justify-center bg-gray-300'>
        <p>created by <span className='font-bold'>Wojciech Łotysz</span> & <span className='font-bold'>Maciej Krawczyk</span> &copy;</p>
    </footer>
  )
}

export default Footer